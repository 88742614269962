import React from 'react';
import Box from '@mui/material/Box';

const Container = ({ children, narrow, ...rest }) => (
  <Box
    maxWidth={narrow ? 720 : { sm: 720, md: 1000 }}
    width={'100%'}
    m={'0 auto'}
    px={2}
    // pt={{ xs: 4, sm: 6, md: 8 }}
    // py={{ xs: 4, sm: 6, md: 8 }}
    {...rest}
  >
    {children}
  </Box>
);

export default Container;
